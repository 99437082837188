<template>
  <div>
    <AppBar :title="$t('shoppingCenters.shoppingCentersUAE')"/>
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-card-title>
        <v-btn v-if="userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole" color="secondary" :to="{ name: 'AddShoppingCenterUAE' }" depressed :disabled="centers.length > 0" rounded>{{ this.$t('shoppingCentersList.add') }}</v-btn>
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t('shoppingCentersList.columns.name') }}</th>
              <th class="text-left">{{ $t('shoppingCentersList.columns.company') }}</th>
              <th class="text-left">{{ $t('shoppingCentersList.columns.subdomain') }}</th>
              <th class="text-left">{{ $t('shoppingCentersList.columns.active') }}</th>
              <th style="width: 100px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="center in centers" :key="center.id">
              <td @click="$router.push({ name: 'EditShoppingCenterUAE', params: { id: center.id } })">{{ center.name }}</td>
              <td>{{ center.company }}</td>
              <td>{{ center.subdomain }}</td>
              <td>
                <v-switch color="secondary" v-model="center.active" @change="changeStatus(center.id, center.active)" class="mt-0" hide-details></v-switch>
              </td>
              <td>
                <v-btn depressed @click="$router.push({ name: 'EditShoppingCenterUAE', params: { id: center.id } })" rounded>{{ $t('shoppingCentersList.columns.edit') }}</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
import { adminRoleId, managerRoleId, employeeRoleId, globalAdminId } from '@/utils/helpers'
import Service from '@/services'
export default {
  name: 'Centers',
  data () {
    return {
      centers: []
    }
  },
  created () {
    this.getCenters()
  },
  computed: {
    userRoleId () {
      return this.$store.getters.userRoleId
    },
    adminRole () {
      return adminRoleId
    },
    globalAdminRole () {
      return globalAdminId
    },
    employeeRole () {
      return employeeRoleId
    },
    managerRole () {
      return managerRoleId
    }
  },
  methods: {
    async getCenters () {
      this.loading = true
      try {
        const { data } = await Service.get('/DubaiShoppingCenters')
        this.centers = data
      } catch (error) {}
      this.loading = false
    },
    async changeStatus (id, status) {
      if (status) {
        await Service.put(`/ShoppingCenters/${id}/Activate`)
      } else {
        await Service.put(`/ShoppingCenters/${id}/Deactivate`)
      }
    }
  }
}
</script>
